export default {
  astrobirdz: {
    56: "0x7f3E9bdB55a0fA72BD6025C0ee1dfC3276cE3CF9",
   
  },
  astroStake: {
    56: "0x26acfbF060d2Ca49F50d7793b22633fd8DFa28E5",
    
  },
};
