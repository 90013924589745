
export const connectorLocalStorageKey = "connectorId";

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
   BSC = 'bsc',
}

export const NetworkContextName = 'NETWORK'
